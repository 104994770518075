<template>
    <b-row v-if="stock">
        <b-col lg="6">
            <Doughnut
                :chart-options="chartOptions"
                :chart-data="chartData"
                chart-id="pie"
                :styles="style"
            />
        </b-col>
        <b-col lg="6">
            <table class="table table-bordered table-cwo">
                <tr>
                    <th>Station</th>
                    <th style="text-align: right">Value</th>
                </tr>
                <tr v-for="(item,index) in tableValues" :key="index">
                    <td>
                        <b-badge :style="'background-color: '+item.color+'!important; padding: 5px '">
                            {{ item.name }}
                        </b-badge>
                    </td>
                    <td style="text-align: right">{{ item.value.toFixed(2) }} EUR</td>
                </tr>
                <tr>
                    <th>Total:</th>
                    <th style="text-align: right">{{ total.toFixed(2) }} EUR</th>
                </tr>
            </table>
        </b-col>
    </b-row>

</template>

<script>
import {Doughnut} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    components: {
        Doughnut
    },
    created() {
        this.load()
    },
    data() {
        return {
            stock: null,
            tableValues: [],
            style: {
                width: '800px',
                position: 'relative'
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: []
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        }
    },
    methods: {
        load() {
            this.$Analytics.getCollection({}).then((response) => {
                this.stock = response.data["data"]
                let index = 0;
                this.stock.forEach(element => {
                    const color = this.getRandomColor(index++)
                    this.tableValues.push({
                        name: element.station + ' - ' + element.name,
                        value: parseFloat(element.total),
                        color: color
                    })
                    this.chartData.labels.push(element.station + ' - ' + element.name)
                    this.chartData.datasets[0].data.push(parseFloat(element.total))
                    this.chartData.datasets[0].backgroundColor.push(color)
                });
            });
        },
        getRandomColor(index) {
            let colors = ['#DC7633', '#D98880', '#BB8FCE', '#7FB3D5', '#85C1E9', '#76D7C4', '#73C6B6', '#7DCEA0', '#82E0AA', '#F7DC6F', '#F8C471', '#F0B27A', '#E59866']
            return colors[index];
        }
    },
    computed: {
        total() {
            let total = 0
            this.tableValues.forEach(element => {
                total += element.value
            });
            return total
        }
    }
}
</script>
